<!--
 * @Author: 月魂
 * @Date: 2021-03-24 14:03:10
 * @LastEditTime: 2021-06-25 12:00:11
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \stoms-mp\src\views\CardOrder.vue
-->
<template>
  <div class="box">
    <van-nav-bar
      title="订单"
      fixed
      placeholder
    >
      <template #right>
        <van-icon name="wap-home-o" size="18" @click="jumpHome" />
      </template>
    </van-nav-bar>
    <div class="card">
      <img :src="data.image_url" class="cardImg">
    </div>
    <div class="content">
      <div class="item">
        <van-row>
          <van-col span="6">类型</van-col>
          <van-col span="18">{{ data.name }}</van-col>
        </van-row>
        <van-row>
          <van-col span="6">数量</van-col>
          <van-col span="18">1 张</van-col>
        </van-row>
        <van-row>
          <van-col span="6">金额</van-col>
          <van-col span="18"><span style="color: #D9001B">￥{{ data.price }}</span></van-col>
        </van-row>
        <van-row v-if="data.type == 1">
          <van-col span="6">总次数</van-col>
          <van-col span="18">{{ data.total_times }} 次</van-col>
        </van-row>
        <van-row v-else>
          <van-col span="6">有效期</van-col>
          <van-col span="18">{{ data.period }} 天</van-col>
        </van-row>
      </div>
    </div>
    <div class="agree">点击提交订单则视为同意 <router-link :to="{ name: 'Contract' }">《会员卡服务条款》</router-link></div>
    <van-goods-action safe-area-inset-bottom>
      <van-goods-action-button
        :disabled="disabled"
        type="danger"
        text="提交订单"
        @click="buyCard"
      />
    </van-goods-action>
  </div>
</template>

<script>
import Vue from 'vue';
import { getCardDetail } from '@/api/card'
import { addOrder } from '@/api/order'
import { NavBar, Row, Col, GoodsAction, GoodsActionButton, Toast, Icon, Dialog } from 'vant';
Vue.use(NavBar).use(Row).use(Col).use(GoodsAction).use(GoodsActionButton).use(Toast).use(Icon);
export default {
  name: 'CardOrder',
  data() {
    return {
      data: {
      },
      disabled: false
    }
  },
  mounted() {
    localStorage.removeItem('redirectUrl')
    getCardDetail(this.$route.params.id).then(res => {
      if (res.code !== 0) return
      this.data = res.data
    })
  },
  methods: {
    jumpHome() {
      this.$router.replace('/')
    },
    beforeClose(action, done) {
      if (action === 'confirm') {
        // 导入手机号认证页
        done()
        localStorage.setItem('redirectUrl', this.$route.path)
        this.$router.push('/mobile')
      } else {
        done()
      }
    },
    buyCard() {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'))
      if (!userInfo) {
        this.$router.push('/my')
      }
      if (!userInfo.mobile) {
        // 提示用户需要补充信息，确定后导入手机号认证页面，取消则停留当前页
        Dialog.confirm({
          title: '提示',
          message: '请补充手机号',
          beforeClose: this.beforeClose
        }).catch(err => err)
      } else {
        const params = {
          cardOrderDtsDTOList: [
            {
              goodId: this.$route.params.id,
              quantity: 1
            }
          ]
        }
        addOrder(params).then(res => {
          if (res.code === 0) {
            this.$router.replace(`/pay/${res.data.id}`)
          } else if (res.code === 10013) {
            Dialog.alert({
              title: '提示',
              message: '您有多个订单尚未处理,请完成后再进行购买',
            }).then(() => {
              this.disabled = true
              this.$router.replace('/order')
            });
          } else if (res.code === 10014) {
            Dialog.alert({
              title: '提示',
              message: '该卡种您已达到购买上限',
            }).then(() => {
              this.disabled = true
              this.$router.replace('/')
            });
          } else if (res.code === 10015) {
            Dialog.alert({
              title: '提示',
              message: '该商品已售罄',
            }).then(() => {
              this.disabled = true
            })
          } else {
            Dialog.alert({
              title: '提示',
              message: res.msg,
            })
          }
        })
      }
    },
  },
}
</script>

<style lang="less" scoped>
.box {
  min-height: 100%;
  background-color: #f9f9f9;
  padding-bottom: 96px;
  .card {
    box-sizing: border-box;
    width: 355px;
    border-radius: 20px;
    overflow: hidden;
    margin:  10px auto;
    .cardImg {
      display: block;
      width: 100%;
      height: 150px;
    }
  }
  .content {
    background-color: #fff;
    padding: 10px;
    font-size: 14px;
    color: #555;
    line-height: 2;
    margin-bottom: 10px;
  }
  .agree {
    position: fixed;
    width: 100%;
    bottom: 50px;
    left: 0;
    font-size: 12px;
    color: #7f7f7f;
    background-color: #fff;
    padding: 10px;
  }
}
</style>